import { parse } from "@conform-to/zod";
import { LockClosedIcon } from "@radix-ui/react-icons";
import {
  ActionFunctionArgs,
  data,
  LoaderFunctionArgs,
  redirect,
} from "@remix-run/node";
import {
  Form,
  Link,
  MetaFunction,
  useActionData,
  useLoaderData,
} from "@remix-run/react";
import { eq } from "drizzle-orm";
import { ClockIcon } from "lucide-react";
import { z } from "zod";
import { Button } from "~/components/ui/button";
import { FormLabel, FormMessage, useConformForm } from "~/components/ui/form";
import { Input } from "~/components/ui/input";
import { db } from "~/db.server";
import { sendEmail } from "~/lib/mailing.server";
import { rateLimit } from "~/lib/rate-limit.server";
import { generateLoginTotp, getVerifyUrl } from "~/lib/totp.server";
import { User } from "~/schema.server";
import { maybeUser } from "~/sessions.server";

const schema = z.object({
  email: z.string().email().toLowerCase(),
});

export const meta: MetaFunction = () => {
  return [{ title: "Login - MUNOG" }];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const { user } = await maybeUser(request);

  if (user && user.schoolId) throw redirect("/manage/school");
  if (user && user.role === "admin") throw redirect("/admin");
  return {
    LOGIN_ACTIVE: true,
  };
}

export async function action({ request }: ActionFunctionArgs) {
  await rateLimit(request, { limit: 10, key: "login" });

  const formData = await request.formData();
  const submission = parse(formData, { schema });
  if (!submission.value || submission.intent !== "submit") {
    return data({ submission });
  }

  const user = await db.query.User.findFirst({
    where: eq(User.email, submission.value.email),
  });

  if (!user) {
    submission.error["email"] = [`That email is not registered.`];
    return data({ submission }, { status: 400 });
  }

  const {
    otp: generatedOtp,
    charSet,
    algorithm,
    digits,
    period,
    secret,
  } = generateLoginTotp();

  await db
    .update(User)
    .set({
      emailVerification: {
        algorithm,
        digits,
        period,
        secret,
        charSet,
        target: user.email,
        action: "login",
      },
    })
    .where(eq(User.id, user.id));

  const url = getVerifyUrl({
    request,
    otp: generatedOtp,
    userId: user.id,
    action: "login",
  });

  await sendEmail({
    from: "no-reply@munog.com",
    to: user.email,
    subject: "MUNOG: Sign in to your account",
    text: `You can use the following code to sign in to MUNOG: ${generatedOtp}\n\nYou can alternatively use this URL: ${url}`,
  });

  return redirect(`/verify/${user.id}`);
}

export default function Login() {
  const { LOGIN_ACTIVE } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();

  const { FormContext, FormItem, context } = useConformForm({
    onValidate({ formData }) {
      return parse(formData, { schema });
    },
    lastSubmission: actionData?.submission
  });

  if (LOGIN_ACTIVE === false) {
    return (
      <div className="bg-no-repeat bg-cover bg-gradient-to-tl from-black via-slate-900 to-blue-950 min-h-screen pb-12">
        <div className="max-w-md mx-auto">
          <h1 className="text-2xl border-b border-slate-600 p-4 text-center">
            MUNOG
          </h1>
        </div>
        <main className="mx-auto [view-transition-name:login-box] max-w-lg mt-12 lg:p-8 rounded-lg text-center">
          <ClockIcon className="mx-auto  h-16 w-16 mb-8  text-white/90" />
          <h2 className="[view-transition-name:headline] text-xl">
            The login is not available yet.
          </h2>
          <div className="my-2">You will be notified when it is available.</div>
          <br />
          <Button asChild variant="secondary">
            <Link to="/">Go back</Link>
          </Button>
        </main>
      </div>
    );
  }

  return (
    <main className="max-w-lg mx-auto pt-12">
      <LockClosedIcon className="mx-auto h-16 w-16 mb-8  text-white/90" />
      <FormContext context={context}>
        <Form
          method="post"
          className="block p-8 m-2 bg-slate-950 border-slate-700 rounded-lg border shadow-xl"
        >
          <h2 className="text-xl font-bold mb-4">Login</h2>
          <FormItem name="email" className="my-8">
            <FormLabel>Your Email</FormLabel>
            <Input type="email" placeholder="john.doe@example.com" />
            <FormMessage />
          </FormItem>
          <div className="mt-6 flex justify-end gap-3">
            <Button asChild variant="secondary">
              <Link to="/">Back</Link>
            </Button>
            <Button type="submit">Login</Button>
          </div>
        </Form>
      </FormContext>
    </main>
  );
}
